/* eslint-disable no-unused-vars */

import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './registration-style'
import {
  Layout,
  Loading,
  SEO,
} from '../../ui'

function RegistrationView({
  children,
  // the page will be ready after required data is preloaded
  pageReady,
  seoTitle,
}) {
  const { t } = useTranslation()
  const {
    containerFluid,
    container,
  } = useStyles()

  return (
    <Layout>
      {/* passing page specific data for SEO */}
      {/* TODO: add seo title */}
      <SEO title={seoTitle} />
      {
        pageReady ? (
          <div className={containerFluid}>
            <div className={container}>
              {children}
            </div>
          </div>
        ) : (
          <Loading />
        )
      }
    </Layout>
  )
}

export default RegistrationView
