/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import URI from 'urijs'
import { Formik } from 'formik'
import {
  cancelRequest,
  useAuth,
  useAddresses,
  useSystemSettings,
  useUser,
  useOmnitechApp,
} from 'react-omnitech-api'
import {
  useAlert,
  useAnalytics,
  useCart,
  useLink,
  useI18n,
} from '../../hook'
import {
  getSocialMediaAvailable,
  getRegistrationFormInitialValues,
  transformRegistrationFormValidationSchema,
} from '../../helpers'
import RegistrationForm from './registration-form'
import RegistrationView from './registration-view'

function RegistrationController({
  location, pathContext,
}) {
  // prepare hook
  const alert = useAlert()
  const { navigate } = useLink()
  const { t } = useTranslation()
  const { fetchCountries } = useAddresses()
  const { getSystemSetting } = useSystemSettings()
  const { auth, createRegistration, setAuth } = useAuth()
  const {
    cartId,
    createCart,
    initCart,
    mergeCart,
  } = useCart()
  const { availableLocales, locale } = useI18n()
  const { createUsersOmniAuthOtps } = useUser()
  const { api } = useOmnitechApp()
  const { trackEvent } = useAnalytics()

  // hook with dependencies
  const preferredCountryCodes = getSystemSetting('country.preferred_country_codes')
  const genderSelection = getSystemSetting('account.gender_selection', [])
  const formConfig = getSystemSetting('account.user_registration_fields', {})
  const lastNameFirst = getSystemSetting('last_name_first', false)
  // [FL] Don't use page.privacy_url or page.terms_url from system settings!
  //      They are not for frontend
  const privacyUrl = '/pages/privacy'
  const termsUrl = '/pages/terms'
  // TODO get array all social sign in enable
  const socialAvailable = getSocialMediaAvailable(getSystemSetting)

  // internal states
  const [countriesEntities, setCountriesEntities] = useState([])
  const [defaultCountryCallingCode, setDefaultCountryCallingCode] = useState('')
  const [formDisabled, setFormDisabled] = useState(true)
  const [pageReady, setPageReady] = useState(false)
  const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false)

  // local variable
  const redirectUrl = useMemo(() => _.get(location, 'state.redirectUrl'), [location])
  const callbackState = useMemo(() => _.get(location, 'state.callbackState'), [location])
  const seoTitle = t('screens.registration.seo.title')

  // locale options
  const defaultLocale = locale
  const localeOptions = _.map(availableLocales, (availableLocale) => ({
    value: availableLocale,
    label: t('ui.locale', { context: availableLocale }),
  }))

  // transform the countries to usable format in select
  const countryCallingCodeOptions = useMemo(() => (
    countriesEntities.map((country) => ({
      label: country.callingCode,
      value: country.callingCode,
    }))
  ), [countriesEntities])

  // useMemo for caching variables
  const formInitialValues = useMemo(() => (
    getRegistrationFormInitialValues({ formConfig, defaultCountryCallingCode, defaultLocale })
  ), [defaultLocale, defaultCountryCallingCode, formConfig])

  const formValidationSchema = useMemo(() => (
    transformRegistrationFormValidationSchema(formConfig)
  ), [formConfig])

  const requiredFields = useMemo(() => (
    _.reduce(formConfig, (result, current, key) => {
      const newResult = result
      if (current.required) {
        newResult[key] = true
        if (current.requireConfirmation) {
          newResult[`${key}Confirmation`] = true
        }
      }
      return newResult
    }, {})
  ), [formConfig])

  /**
   * fetchCountriesApi
   * function to call api and fetch countries for country calling code options
   */
  const fetchCountriesApi = useCallback(async () => {
    try {
      const fetchCountriesApiQuery = getSystemSetting('api.v2.addresses.countries.registration.ecom.query', {})
      const { countries } = await fetchCountries({
        params: {
          pageSize: 999,
          ...fetchCountriesApiQuery,
        },
        arrayFormat: 'brackets',
      })
      const countriesCodes = _.split(preferredCountryCodes, ' ')
      const firstCountryCode = _.head(countriesCodes)
      const firstCountry = _.find(countries, { alpha2: firstCountryCode }) || _.first(countries)
      setCountriesEntities(countries)
      setDefaultCountryCallingCode(_.get(firstCountry, 'callingCode'))
      setFormDisabled(false)
      setPageReady(true)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSystemSetting, fetchCountries])

  // TODO: do real logic to determine which approach to be used
  /**
   * getRegistrationApproach
   * determine registration approach base on form value
   */
  function getRegistrationApproach() {
    if (_.get(formConfig, 'password.required')) {
      if (_.get(formConfig, 'email.required')) {
        return 'email'
      }
      return 'phone_password_v2'
    }
    return 'phone_and_sms_token'
  }

  /**
   * handle SmsTokenButton callbacks
   */
  function onRequestSmsTokenSuccess() {
    setShowVerificationCodeInput(true)
  }
  function onRequestSmsTokenError(error) {
    const generalError = _.get(error, 'generalError', {})
    alert.show(generalError.message)
  }

  /**
   * handleMergeCart
   * after user registration success, if a guest cart
   */
  async function handleMergeCart() {
    const options = {
      params: {
        includes: ['cart_line_properties'],
      },
    }
    try {
      await mergeCart(options)
    } catch (error) {
      // continue the registration process
      console.warn('[Project] handleMergeCart error: ', error)
    }
  }

  /**
   * handleRegistration
   *
   * @param {*} values, object contain all value from input
   */
  async function handleRegistration(values) {
    alert.remove()
    setFormDisabled(true)

    const userRegistrationFields = _.keys(formConfig)

    // prepare api call payload
    const data = {
      registration: {
        approach: getRegistrationApproach(values),
        ..._.pick(values, userRegistrationFields),
        // TODO: API has issue, not support null for gender
        gender: _.isEmpty(values.gender) ? null : values.gender,
      },
    }

    // TODO: add token base on some setting
    // token is required when,
    // 1. phone is required
    // 2. phone is optional, but phone is provided
    data.registration.token = values.token

    // calling api for registration and control the flow of page redirect
    try {
      const { session } = await createRegistration(data)

      // update auth token and user id
      await setAuth({
        ...auth,
        ..._.pick(session, ['authToken', 'userId']),
      })

      // create cart instead of merge cart if cart state is isolated
      if (_.isEqual(_.get(initCart, 'state'), 'isolated')) {
        await createCart({
          params: {
            includes: [
              'cart_shipments',
              'cart_shipments.inventory_store',
            ],
          },
        })
      } else if (!_.isEmpty(cartId)) {
        // merge guest cart to user cart
        await handleMergeCart()
      }

      try {
        trackEvent('customerRegister', {}, {
          user: _.pick(values, ['email', 'phone', 'firstName', 'lastName']),
        })
      } catch (ex) {
        // do nothing
      }

      // handle redirect to different page after sign in successfully
      navigate(
        _.isEmpty(redirectUrl) ? '/account/' : redirectUrl,
        {
          replace: true,
          state: callbackState,
        },
      )
    } catch (error) {
      setFormDisabled(false)
      throw error
    }
  }

  /**
   * handleSubmit
   *
   * Formik onSubmit callback
   *
   * @param {*} values form values from Formik
   * @param {*} actions includes an object containing a subset of the injected props and methods
   */
  const handleSubmit = async (values, actions) => {
    try {
      await handleRegistration(values)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      const validationError = _.get(error, 'validationError', {})
      const errorMessage = _.isEmpty(validationError.message)
        ? generalError.message
        : validationError.message
      alert.show(errorMessage)
    } finally {
      actions.setSubmitting(false)
    }
  }

  /**
   * onSocialSignIn
   *
   * redirect facebook
   * TODO implement logical for other sacial platforms
   */

  async function onSocialSignIn(provider) {
    alert.remove()
    const url = new URI(location.href)
    // use a cross-platform application like NGROK to test in localhost
    const urlRedirect = `${url.protocol()}://${url.host()}/${pathContext.locale}/oauth/register`

    // prepare api call payload
    const data = {
      omniAuthOtp: {
        provider,
        mode: 'redirect',
        redirect: false,
        redirectUrl: urlRedirect,
      },
    }

    // calling api for create session and control the flow of page redirect
    try {
      const { omniAuthOtp } = await createUsersOmniAuthOtps(data)
      const urlAPI = _.get(api, 'host')
      const urlAuth = _.get(omniAuthOtp, 'requestPhasePath')

      const newUrl = `${urlAPI}${urlAuth}`
      // handle redirect to different page after sign in successfully
      navigate(
        newUrl,
        { replace: false },
      )
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)

      setFormDisabled(false)

      throw error
    }
  }

  const onLoginClick = () => {
    // pass through state to login form
    navigate(
      '/login/',
      _.pick(location, ['state']),
    )
  }

  /**
   * redirect to account page if user is already logged in
   */
  useEffect(() => {
    if (auth.userId) {
      navigate('/account/', { replace: true })
    }
  }, [])

  /**
   * get countries for country call code option
   */
  useEffect(() => {
    fetchCountriesApi()

    return () => {
      cancelRequest.cancelAll(['fetchCountries'])
    }
  }, [fetchCountriesApi])

  /**
   * cancel api call when leaving the page
   */
  useEffect(() => () => (
    cancelRequest.cancelAll(['createRegistration'])
  ), [])

  const viewProps = {
    pageReady,
    seoTitle,
  }

  const formPorps = {
    countryCallingCodeOptions,
    genderSelection,
    localeOptions,
    formConfig,
    formDisabled,
    lastNameFirst,
    requiredFields,
    showVerificationCodeInput,
    socialAvailable,
    privacyUrl,
    termsUrl,
    onLoginClick,
    onRequestSmsTokenSuccess,
    onRequestSmsTokenError,
    onSocialSignIn,
  }

  return (
    <RegistrationView {...viewProps}>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validateOnChange
        validationSchema={formValidationSchema}
        onSubmit={handleSubmit}
      >
        <RegistrationForm {...formPorps} />
      </Formik>
    </RegistrationView>
  )
}

export default RegistrationController
